<template>
  <div class="nothing">
    <div class="icon">
      <img src="https://taotuo.oss-cn-hangzhou.aliyuncs.com/uniapp/static/clock.png" alt="">
      <div class="text">终于等到您，期待您的光临！</div>
      <div class="texts" @click="back">返回首页</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'success',
    methods: {
      back() {
        this.$router.push({
          path: '/'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
  .nothing{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 512px;

    .icon{
      width: 230px;
      position: relative;
      text-align: center;

      img{
        width: 150px;
      }

      .text{
        width: 100%;
        text-align: center;
        font-size: 16px;
        color: #666666;
      }

      .texts{
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #FF8178;
        cursor: pointer;
        margin-top: 20px;
      }
    }
  }
</style>
